import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const AdminPage = () => {
  const user = useSelector((state) => state.token.user);
  console.log(atob(user?.role));
  return <>{atob(user?.role) === "Admin" ? <Outlet /> : <Navigate to="/" replace="true" />}</>;
};

export default AdminPage;
