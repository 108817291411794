import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, Container, NavDropdown, Badge } from 'react-bootstrap';

const Header = () => {

    const user = useSelector((state) => state.token.user);

    const Login = () => {
        if (localStorage.getItem("tokenBearer")) {
            return <Nav.Item>
                        <Nav.Link as={NavLink} to='/logout' >
                            Logout&nbsp;&nbsp;<Badge pill bg="secondary">{(user?.role ? atob(user?.role) : "")}</Badge>
                        </Nav.Link>
                    </Nav.Item>
          } else {
            return <Nav.Item>
                        <Nav.Link as={NavLink} to='/login' >
                            Login
                        </Nav.Link>
                    </Nav.Item>
          }
    }

    const NavHeader = () => {
        if (localStorage.getItem("tokenBearer")) return (
        <><Nav.Item>
            <Nav.Link as={NavLink} to='/users' >
                Profil
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={NavLink} to='/users/my-certificates' >
                Sertifikatku
            </Nav.Link>
        </Nav.Item>
        </>);
    }

    const AdminHeader = () => {
        if(user?.role) {
            const userRole = atob(user?.role)
            if (userRole === 'Admin') {
                return <>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/daftar-sertifikat-klien' >
                                Cek Sertifikat
                            </Nav.Link>
                        </Nav.Item>
                        <NavDropdown title="Menu Admin" id="basic-nav-dropdown">
                            
                            <NavDropdown.Item as={NavLink} to='/admin/list-users' >List User</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to='/admin/add-certificate' >Tambah Certificate</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to='/' >Tambah Jenis Certificate</NavDropdown.Item>
                        </NavDropdown>
                        </>;
            }

        }
    }

    useEffect(() => {},[user])
    

    return (
        <header>
            <Navbar bg='light' variant='light'>
                <Container>
                    <Nav className="me-auto">
                        <Nav.Item>
                            <a className='nav-link' target="_blank" rel="noreferrer" href='https://primemsi.co.id/'>Home</a>
                        </Nav.Item>
                        <Nav.Item>
                            <a className='nav-link' target="_blank" rel="noreferrer" href='https://primemsi.co.id/tentang-kami/'>About Us</a>
                        </Nav.Item>
                        <Nav.Item>
                            <a className='nav-link' target="_blank" rel="noreferrer" href='https://primemsi.co.id/hubungi-kami/'>Contact Us</a>
                        </Nav.Item>
                        <Nav.Item>
                            <a className='nav-link' target="_blank" rel="noreferrer" href='https://primemsi.co.id/layanan-kami/'>Our Services</a>
                        </Nav.Item>
                        <NavHeader />
                        <AdminHeader />
                        <Login />
                        
                    </Nav>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header;