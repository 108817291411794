import React, {useState} from 'react';
import { Button, Container, Form } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import Header from '../navbar/Header';

const Home = () => {

    const [noSertifikat, setNoSertifikat] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {

        navigate(`/terdaftar/${noSertifikat}`);
    }

    return (

        <>
        <Header />
        <br /><br />
        <Container>
            <Form
                action=''
                method='POST'
                encType=''
                onSubmit={(event) => { event.preventDefault(); handleSubmit() }} >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Cari Sertifikat</Form.Label>
                    <Form.Control type="text" placeholder="No Sertifikat" onChange={event => setNoSertifikat(event.target.value)} />
                </Form.Group>

                <Button type='submit'>Cari</Button>
            </Form>
        </Container>
        </>
    );
};

export default Home;