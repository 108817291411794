import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import api from "../../lib/api";
import { setToken } from '../../reducers/token-store';

const Logout = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = async () => {

        try{
            const url = `/api/auth/logout`;
            const response = await api.post(url);
            dispatch(setToken(response?.data));
            localStorage.clear();
            navigate("/", {replace:true});

        } catch(error) {
            console.log(error);
        }
    } 

    useEffect(() => {
        logout();
        //eslint-disable-next-line
    },[])
    return ('');

    
}
export default Logout