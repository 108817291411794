import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/api';
import { setToken } from '../../reducers/token-store';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        try {
            const url = `/api/auth/login`;
            const response = await api.post(url, {
                email: email,
                password: password
            });
            if(response?.data?.status === "200"){
                console.log(response.data);
                dispatch(setToken(response.data));
                navigate("/");
            }

            if (response?.data?.status === "401") {
                alert(response?.data?.message);
            }
        } catch (error) {
            console.log(error);
            alert("Ga bisa masuk");
        }

    }
    
    return (
        <>
        <br /><br />
        <Container>
            <Row>
                <Col>
                    <Form
                        action=''
                        method='POST'
                        encType=''
                        onSubmit={(event) => { event.preventDefault(); handleSubmit() }} >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={event => setEmail(event.target.value)} />
                            <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={event => setPassword(event.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default Login;