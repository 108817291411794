import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import Header from '../navbar/Header';

import { useNavigate, useParams } from 'react-router-dom';

import api from '../../lib/api';

const User = () => {

    const [user, setUser] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const name = useRef();
    const email = useRef();
    const password = useRef();
    const passwordConfirmation = useRef();

    const getUser = async (id) => {
        try {
            const url = `/api/users/${id}`;
            const response = await api.get(url);
            const payload = {...response?.data?.data};
            if(response?.data?.status === "404") navigate("/");
            setUser(payload);
            console.log(payload);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        try {
            if (params.id) {
                const url = `/api/users/${params.id}`;
                console.log(url);
                const response = await api.put(url,{
                    name: name.current.value,
                    email: email.current.value,
                    password: password.current.value,
                    password_confirmation: passwordConfirmation.current.value,
                });
                const payload = response?.data?.data;
                console.log(payload);
            } else {
                const url = `/api/users/`;
                console.log(url);
                const response = await api.post(url,{
                    name: name.current.value,
                    email: email.current.value,
                    password: password.current.value,
                    password_confirmation: passwordConfirmation.current.value,
                });
                console.log(response?.data?.data);

                if (response?.data?.status === "401" || response?.data?.status === "400") alert(response?.data?.message)
                if (response?.data?.status === "201") { alert("New User Added");navigate("/admin/list-users");};
            
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        if(params.id){
            
            getUser(params.id);
        }
    
    }, [])
    

    return (
        <>
            <Header />
            <br /><br />
            <Container>
                <Form
                action=''
                method='POST'
                encType=''
                onSubmit={(event) => { event.preventDefault(); handleSubmit(); }}>
                    <Row className="mt-4">
                        <Col md={4}>Nama</Col>
                        <Col md={8}><Form.Control ref={name} placeholder={"Nama"} defaultValue={user?.name} /></Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4}>Email</Col>
                        <Col md={8}><Form.Control ref={email} placeholder={"Email"} defaultValue={user?.email} /></Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4}>Password</Col>
                        <Col md={8}><Form.Control ref={password} placeholder={"Password"} type="password" /></Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4}>Confirm Password</Col>
                        <Col md={8}><Form.Control ref={passwordConfirmation} placeholder={"Konfirmasi Password"} type="password" /></Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4}><Button variant="primary" type="submit">Submit</Button></Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
};

export default User;