import React, { useState, useEffect} from "react";

import api from '../../lib/api';
import { useParams, useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';
import Header from "../navbar/Header";


const Certificate = () => {

    const [certificate, setCertificate] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    const fetchCertificate = async (id) => {
        try {
            const url = `/api/certificate/${id}`;
            const response = await api.get(url);
            const payload = {...response?.data?.data};
            if(response?.data?.status === "404") {alert("Data tidak ditemukan"); navigate("/");}
            setCertificate(payload);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        if (params.id) {
            fetchCertificate(params.id);
        }
        
        // eslint-disable-next-line
    }, [params.id])
    return (
        <>
        <Header />
        <br /><br />
        <Container>
            <Row>
                <Col md={4}>
                    No Sertifikat
                </Col>
                <Col md={4}>

                    : {certificate?.no_sertifikat}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Nama Pemilik Sertifikat
                </Col>
                <Col md={4}>

                    : {certificate?.user_id?.name}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Nama Perusahaan/Instansi
                </Col>
                <Col md={4}>

                    : {certificate?.instansi}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Nama Kegiatan
                </Col>
                <Col md={4}>

                    : {certificate?.kegiatan}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Tanggal Kegiatan
                </Col>
                <Col md={4}>

                    : {certificate?.tanggal_kegiatan}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Status
                </Col>
                <Col md={4}>

                    : {certificate?.status}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    Download
                </Col>
                <Col md={4}>

                    : <Button onClick={
                        (e) => {
                            e.preventDefault()
                            window.location.replace(certificate?.link_sertifikat)
                        }
                    }>Download</Button>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default Certificate;