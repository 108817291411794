import React, { useRef, useState, useEffect } from "react";

import api from '../../lib/api';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

import { Container, Row, Form, Button } from 'react-bootstrap';
import Header from "../navbar/Header";


const AddCertificate = () => {

    const [userID, setUserID ] = useState('');
    const no_sertifikat = useRef();
    const instansi = useRef();
    const kegiatan = useRef();
    const tanggal_kegiatan = useRef();
    const link_sertifikat = useRef();
    const status = useRef();
    const [nameUserList, setNameUserList] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        console.log(status.current.value);
        try {
            const url = `/api/certificate/`;
            const response = await api.post(url, {
                no_sertifikat: no_sertifikat.current.value,
                instansi : instansi.current.value,
                kegiatan : kegiatan.current.value,
                tanggal_kegiatan : tanggal_kegiatan.current.value,
                link_sertifikat: link_sertifikat.current.value,
                status: status.current.value,
                user_id: userID,
            });
            // const payload = {...response?.data?.data};
            console.log(response?.data);
            if(response?.data?.status === "201") { alert(response?.data?.message); navigate("/") }

        } catch (error) {
            console.log(error);
        }

    }

    const getUserList = async () => {
        try {
            const url = `/api/users`;
            const response = await api.get(url);
            const payload = [...response?.data?.data]
            console.log(payload);
            let data = [];
            // eslint-disable-next-line
            payload.map(payload => {data.push({value:payload.id,label:payload.name});});
            setNameUserList(data);
        } catch (error) {
            console.log(error);
            return error;
        } 
        
    }

    useEffect(() => {
      getUserList();
    
    }, [])

    return (
        <>
        <Header />
        <br /><br />
        <Container>
            <Form
                action=''
                method='POST'
                encType=''
                onSubmit={(event) => { event.preventDefault(); handleSubmit(); }}>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>No Sertifikat</Form.Label>
                        <Form.Control required ref={no_sertifikat} type="text"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicUserName">
                        <Form.Label>Nama Pemegang Sertifikat</Form.Label>
                        <Select options={nameUserList} required onChange={(e) => {setUserID(e.value); console.log(e.value);}} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>Perusahaan/Instansi</Form.Label>
                        <Form.Control required ref={instansi} type="text"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>Nama Kegiatan</Form.Label>
                        <Form.Control required ref={kegiatan} type="text"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>Link Sertifikat</Form.Label>
                        <Form.Control required ref={link_sertifikat} type="text" />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>Tanggal Kegiatan</Form.Label>
                        <Form.Control required ref={tanggal_kegiatan} type="date"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Status</Form.Label>
                            <Form.Control required ref={status} type="text"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Button type="submit">Submit</Button>
                </Row>
            </Form>
        </Container>

        <br />
        <br />
        <br />
        <br />
        </>
    );
};

export default AddCertificate;