import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const SecurePage = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  return <>{token ? <Outlet /> : <Navigate to="/" replace="true" />}</>;
};

export default SecurePage;
