import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Certificates from './pages/certificate/Certificates';
import MyCertificates from './pages/certificate/MyCertificates';
import Certificate from './pages/certificate/Certificate';
import AdminPage from './pages/security/AdminPages';
import AddCertificate from './pages/certificate/AddCertificates';
import Login from './pages/login/Login';
import Logout from './pages/login/Logout';
import SecurePage from './pages/security/SecurePages';
import Profile from './pages/profile/Profile';
import Users from './pages/user/Users';
import User from './pages/user/User';
import Home from './pages/home/Home';

class App extends Component {
  componentDidMount() {}

  
  
  render() {
    return (
        <Router>
          <Routes>
            <Route exact path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path="/" element={<Home />} />
            <Route path="/daftar-sertifikat-klien" element={<Certificates />} />
            <Route path="/terdaftar/:id" element={<Certificate />} />
            <Route exact path="/users" element={<SecurePage />} >
              <Route exact path="/users" element={<Profile />} />
              <Route exact path="/users/my-certificates" element={<MyCertificates />} />
            </Route>
            <Route exact path="/admin" element={<AdminPage />} >
              
              <Route exact path="/admin/add-certificate" element={<AddCertificate />} />
              <Route exact path="/admin/list-users" element={<Users />} />
              <Route exact path="/admin/list-users/:id" element={<User />} />
              <Route exact path="/admin/list-users/add" element={<User />} />
            </Route>
          </Routes>
        </Router>
    );
  }
}

export default App;
