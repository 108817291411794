import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/api';
import { Container, Col, Row, Table, Button, Pagination } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';

import Header from '../navbar/Header';

const MyCertificates = () => {
    const [certificates, setCertificates] = useState();
    const [dataNull, setDataNull] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pages, setPages] = useState(1);
    const [paginate, setPaginate] = useState(5);
    const [search, setSearch] = useState("");
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const navigate = useNavigate();

    
    const getCertificates = async () => {
        setIsLoaded(true);
        try {
            const url = `/api/my-certificate?page=${pages}&paginate=${paginate}&search=${search}`;
            const response = await api.get(url);
            if (response?.data?.status === "200") {
                const payload = response?.data?.data;
                setCertificates(payload);
                setDataNull(false);
                setPages(response?.data?.data?.current_page);
            } else {
                setDataNull(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoaded(false);
        }

    }

    useEffect(() => {
        getCertificates();
        // eslint-disable-next-line
    }, [dataNull, search, pages, paginate])

    const DataTable = () => {

        if(isLoaded === true) return <tr><td colSpan={8}> Loading</td></tr>
        if(dataNull === true) return <tr><td colSpan={8}> Data Tidak ditemukan</td></tr>
        if(dataNull === false) return (
            certificates?.data && certificates?.data.map( (items,idx) => (
                    <tr key={idx}>
                        <td>{idx+1}</td>
                        <td>{items.no_sertifikat}</td>
                        <td>{items.kegiatan}</td>
                        <td>{items.instansi}</td>
                        <td>{items.tanggal_kegiatan}</td>
                        <td>{items.status}</td>
                        <td><Button onClick={(e) => {e.preventDefault(); navigate(`/terdaftar/${items.no_sertifikat}`);}}>Lihat</Button></td>
                    </tr>
                ))
                )
        
        }
    
    const PaginationPage = () => {
        if (isLoaded === true) return <Col  md={6}>Loading</Col>
        if (dataNull === true) return <Col  md={6}>""</Col>
        if (dataNull === false) return (
                    <Col  md={6}>
                    <Pagination>
                        {certificates?.links && certificates?.links.map( (items,idx) => (
                                <Pagination.Item key={idx} active={items.active} onClick={(e) => {
                                        e.preventDefault();
                                        if(items.url !== null && items.active === false) {
                                            const page = items.label
                                            setPages(page)
                                        }
                                    }} >
                                    {renderHTML(items.label)}
                                </Pagination.Item> 
                                ))}
                    </Pagination>
                    </Col>
        )
    }
    return (
        <>
            
            <Header />
            <br /><br />
            <Container>
                <Row mt={3}>
                    <Col sm={8}></Col>
                    <Col sm={4}>
                        <FormControl type="text" 
                            onChange={event => {

                                setSearch(event.target.value);
                                getCertificates();
                                }}
                            placeholder="Search" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>No. Sertifikat</th>
                                <th>Kegiatan</th>
                                <th>Instansi</th>
                                <th>Tanggal Pelatihan</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <DataTable />
                        </tbody>
                    </Table>                        
                    </Col>  
                </Row>
                
            <Row>
                <PaginationPage />  
                
                <Col md={6}>
                        <Row>
                            <Col md={4}>
                                <div>Page : {certificates && certificates?.current_page} to {certificates && certificates?.to} of {certificates && certificates.total}</div>
                            </Col>
                            <Col md={4}>
                                <FormSelect aria-label="Default select example" defaultValue={paginate} onChange={(e) => {setPaginate(e.target.value)}}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </FormSelect>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
            </Container>
            
        </>
    );
};

export default MyCertificates;