import React, { useRef } from 'react';
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setToken } from '../../reducers/token-store';
import Header from '../navbar/Header';
import api from '../../lib/api';

const Profile = () => {
    const user = useSelector((state) => state.token.user);
    const nama = useRef();
    // const first = useRef(second)
    const email = useRef();
    const password = useRef();
    const password2 = useRef();

    const dispatch = useDispatch();


    const updateProfile = async () => {
        console.log(email.current.value);
        try {
            const url = `/api/profile-update`;
            const response = await api.post(url, {
                user_id: user?.id,
                email: email.current.value,
                name: nama.current.value,
                password: password.current.value,
                password_confirmation: password2.current.value,
            });
            console.log(response);
            if(response?.data?.status === "200"){

                console.log(response.data); 

                dispatch(setToken(response.data));
                password.current.value = "";
                password2.current.value = "";
                
            }

            if (response?.data?.status === "401") {
                console.log(response?.data);
                alert(response?.data?.message);
            }
        } catch (error) {
            console.log(error);
            alert("Ga bisa masuk");
        }
    }

    return (
        <>
            <Header />
            <br /><br />
            <Container>
                <Row>
                    <Col>
                        <Form
                            action=''
                            method='POST'
                            encType=''
                            onSubmit={(event) => { event.preventDefault(); updateProfile(); }}>
                            
                            <Form.Group className="mb-3" controlId="formBasicNama">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control ref={nama} type="text" placeholder="Nama" defaultValue={user?.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control ref={email} type="email" placeholder="Enter email" defaultValue={user?.email} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control ref={password} type="password" placeholder="Password" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword2">
                                <Form.Label>Password 2</Form.Label>
                                <Form.Control ref={password2} type="password" placeholder="Password"  />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default Profile;